import { Tooltip } from 'antd';

const PlaceholderTemplate = ({ tags }: Readonly<NotificationPlaceholdersProps>) => {
  return (
    <div>
      <ul className="list-unstyled m-b-0">
        {tags.map(({ tag, translation }, index) => (
          <li key={`placeholder-list-${tag}-${translation}-${index}`}>
            <small>[{tag}]</small>&nbsp;
            <span>{translation}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

interface NotificationPlaceholdersProps {
  tags: { tag: string; translation: string }[];
}

export default function NotificationPlaceholders({
  tags
}: Readonly<NotificationPlaceholdersProps>) {
  return (
    <>
      <Tooltip
        placement="left"
        overlayClassName="ant-html-tooltip"
        title={<PlaceholderTemplate tags={tags} />}
        trigger="click"
      >
        <i className="fa fa-info-circle text-primary" />
      </Tooltip>
    </>
  );
}
